import React from "react"
import { useParams } from "react-router-dom"
import PageOutlet from "./page_outlet"
import Providers from "../providers"

const Navigation = ({ initial }) => {
  const { scheduleId } = useParams()

  return (
    <Providers initial={{ ...initial, scheduleId }}>
      <PageOutlet currentUser={initial?.currentUser}/>
    </Providers>
  )
}

export default Navigation
